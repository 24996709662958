exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-admisi-beasiswa-calon-mahasiswa-js": () => import("./../../../src/pages/admisi/beasiswa-calon-mahasiswa.js" /* webpackChunkName: "component---src-pages-admisi-beasiswa-calon-mahasiswa-js" */),
  "component---src-pages-admisi-beasiswa-js": () => import("./../../../src/pages/admisi/beasiswa.js" /* webpackChunkName: "component---src-pages-admisi-beasiswa-js" */),
  "component---src-pages-admisi-beasiswa-mahasiswa-js": () => import("./../../../src/pages/admisi/beasiswa-mahasiswa.js" /* webpackChunkName: "component---src-pages-admisi-beasiswa-mahasiswa-js" */),
  "component---src-pages-admisi-index-js": () => import("./../../../src/pages/admisi/index.js" /* webpackChunkName: "component---src-pages-admisi-index-js" */),
  "component---src-pages-admisi-pendaftaran-js": () => import("./../../../src/pages/admisi/pendaftaran.js" /* webpackChunkName: "component---src-pages-admisi-pendaftaran-js" */),
  "component---src-pages-akademik-alur-js": () => import("./../../../src/pages/akademik/alur.js" /* webpackChunkName: "component---src-pages-akademik-alur-js" */),
  "component---src-pages-akademik-index-js": () => import("./../../../src/pages/akademik/index.js" /* webpackChunkName: "component---src-pages-akademik-index-js" */),
  "component---src-pages-akademik-kalender-js": () => import("./../../../src/pages/akademik/kalender.js" /* webpackChunkName: "component---src-pages-akademik-kalender-js" */),
  "component---src-pages-contact-form-js": () => import("./../../../src/pages/contact-form.js" /* webpackChunkName: "component---src-pages-contact-form-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-dosen-dan-staff-dosen-albert-gunadhi-mdx": () => import("./../../../src/pages/dosen-dan-staff/dosen/albert-gunadhi.mdx" /* webpackChunkName: "component---src-pages-dosen-dan-staff-dosen-albert-gunadhi-mdx" */),
  "component---src-pages-dosen-dan-staff-dosen-index-js": () => import("./../../../src/pages/dosen-dan-staff/dosen/index.js" /* webpackChunkName: "component---src-pages-dosen-dan-staff-dosen-index-js" */),
  "component---src-pages-dosen-dan-staff-index-js": () => import("./../../../src/pages/dosen-dan-staff/index.js" /* webpackChunkName: "component---src-pages-dosen-dan-staff-index-js" */),
  "component---src-pages-dosen-dan-staff-staf-js": () => import("./../../../src/pages/dosen-dan-staff/staf.js" /* webpackChunkName: "component---src-pages-dosen-dan-staff-staf-js" */),
  "component---src-pages-fasilitas-index-js": () => import("./../../../src/pages/fasilitas/index.js" /* webpackChunkName: "component---src-pages-fasilitas-index-js" */),
  "component---src-pages-fasilitas-lab-js": () => import("./../../../src/pages/fasilitas/lab.js" /* webpackChunkName: "component---src-pages-fasilitas-lab-js" */),
  "component---src-pages-fasilitas-lingkungan-js": () => import("./../../../src/pages/fasilitas/lingkungan.js" /* webpackChunkName: "component---src-pages-fasilitas-lingkungan-js" */),
  "component---src-pages-fasilitas-olah-raga-js": () => import("./../../../src/pages/fasilitas/olah-raga.js" /* webpackChunkName: "component---src-pages-fasilitas-olah-raga-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-informasi-index-js": () => import("./../../../src/pages/informasi/index.js" /* webpackChunkName: "component---src-pages-informasi-index-js" */),
  "component---src-pages-member-index-js": () => import("./../../../src/pages/member/index.js" /* webpackChunkName: "component---src-pages-member-index-js" */),
  "component---src-pages-member-password-reset-js": () => import("./../../../src/pages/member/password-reset.js" /* webpackChunkName: "component---src-pages-member-password-reset-js" */),
  "component---src-pages-member-registration-form-js": () => import("./../../../src/pages/member/registration-form.js" /* webpackChunkName: "component---src-pages-member-registration-form-js" */),
  "component---src-pages-member-signin-js": () => import("./../../../src/pages/member/signin.js" /* webpackChunkName: "component---src-pages-member-signin-js" */),
  "component---src-pages-member-signup-js": () => import("./../../../src/pages/member/signup.js" /* webpackChunkName: "component---src-pages-member-signup-js" */),
  "component---src-pages-publication-index-js": () => import("./../../../src/pages/publication/index.js" /* webpackChunkName: "component---src-pages-publication-index-js" */)
}

